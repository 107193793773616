<script>
  /* eslint-disable vue/no-unused-components */

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";
import DetailsButtonRenderer from '@/components/ag-grid/renderers/details-button'; 
import DropDownButtonRenderer from '@/components/ag-grid/renderers/dropdown-button'; 
import CustomRenderer from '@/components/ag-grid/renderers/custom'; 
import HeaderRenderer from '@/components/ag-grid/renderers/header'; 
import TagsRenderer from '@/components/ag-grid/renderers/tags'; 
import StatusRenderer from './ag-grid/renderers/status'; 
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { parseErrors} from "@/helpers"
import Swal from 'sweetalert2'
import CustomFieldRenderer from '@/components/ag-grid/renderers/custom-field'; 

export default {
  components: {
    AgGridVue,
    detailsButtonRenderer: DetailsButtonRenderer,
    dropDownButtonRenderer: DropDownButtonRenderer,
    customRenderer: CustomRenderer,
    headerRenderer: HeaderRenderer,
    customFieldRenderer: CustomFieldRenderer,
    statusRenderer: StatusRenderer,
    tagsRenderer: TagsRenderer
  },
  props: {
    directoryId: {
      type: String,
      required: true
    }
  },
  mounted () {
    window.addEventListener("resize", this.debCalculateHeight);
    this.debCalculateHeight();
  },
  unmounted() {
    window.removeEventListener("resize", this.debCalculateHeight);
  },
  data() {
    return {
     
    };
  },
  computed :{
    ...mapGetters('usageProfiles', {
      currentUsageProfile: 'current'
    }),
    allowEdit() {
      if(this.currentUser.isAdmin)
        return true;
      
      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowUpdate;
    },
    allowDelete() {
      if(this.currentUser.isAdmin)
        return true;
      
      if(!this.currentUsageProfile)
        return false;

      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowDelete;
    },
    columnDefs() {
      const { pinnedColumns, columnsOrder, columnsWidth } = this;
      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
     
      //console.log('columnDefs', {admin: this.currentUser.isAdmin, edit: this.allowEdit, allowDelete: this.allowDelete});
      let res = [
        { 
          headerName: "", 
          field: "$details" , 
          width:  50, 
          pinned: true, 
          hide: false, 
          cellClass: 'first-button-cell',
          cellRenderer: 'detailsButtonRenderer', 
          cellRendererParams: { 
            onClick: (item) => {
             
              this.openItem({item, mode: 'view'});
            }
          }
        },
        { 
          headerName: "", 
          field: "$menu",
          width: 40, 
          pinned: true, 
          hide: !this.currentUser.isAdmin && !this.allowEdit && !this.allowDelete, 
          cellClass: 'dropdown-button-cell',
          cellRenderer: 'dropDownButtonRenderer', 
          cellRendererParams: { 
            spinner: (item) => item.id === this.deleting,
            subItems:[
              { class: 'text-primary', iconClass:'bx bx-pencil  me-2', text: this.$t('common.editItem'), hidden:() => !this.allowEdit, onClick: (item) => this.openItem({item, mode: 'edit'})},
              { class: 'text-danger', iconClass:'bx bx-trash-alt me-2', text: this.$t('common.deleteButtonTitle'), hidden:() => !this.allowDelete, onClick: (item) => this.onDelete(item)}
            ]
          }
        },
        { 
          headerName: "#", 
          field: "number" , 
          width: columnsWidth["number"] ?? 80, 
          pinned: pinnedColumns.find(x => x === "number") ? true : false, 
          resizable: true, 
          hide: false, 
          headerComponent: 'headerRenderer' 
        },
        { 
          headerName: this.$t('common.tags'), 
          field: "tags" , 
          width: columnsWidth["tags"] ?? 80, 
          pinned: pinnedColumns.find(x => x === "tags") ? true : false, 
          resizable: true, 
          hide: false, 
          cellRenderer: 'tagsRenderer' ,
           headerComponent: 'headerRenderer'
        },
      ];

      if(this.directory.type === 'Process') {
         res.push({ 
          headerName: this.$t('directory.fields.status'), 
          field: "status" , 
          width: columnsWidth["status"] ?? 80, 
          pinned: pinnedColumns.find(x => x === "status") ? true : false, 
          resizable: true, 
          hide: false, 
          cellRenderer: 'statusRenderer', 
          cellRendererParams: { 
            directory: this.directory
          }
        });
      }
      
      this.customFields.forEach(field => {
        
        const fieldDef = field.type !== 'DirectoryRelatedField' 
          ? field 
          : this.getRelatedFieldDef(field.directoryFieldId, field.directoryRelatedFieldId);

        const fieldPath = field.type !== 'DirectoryRelatedField' 
        ? ("customFields." + field.id) 
        : ("customFields." + field.directoryFieldId + ".customFields." + field.directoryRelatedFieldId);

        res.push({  
          headerName: field.name, 
          field: fieldPath, 
          width: columnsWidth["customFields." + field.id] ?? 200, 
          pinned: pinnedColumns.find(x => x === "customFields." + field.id) ? true : false, 
          resizable: true, 
          cellRendererParams: { 
            def: fieldDef
          },
          cellRenderer: 'customFieldRenderer', 
          hide: field.hidden || (dirAccess && dirAccess.hiddenFields && dirAccess.hiddenFields.indexOf(field.id) >= 0), 
          headerComponent: 'headerRenderer' })
      });

      res.push( { 
        headerName: this.$t('common.lastComment'), 
        field: "lastNote" , 
        width: columnsWidth["lastNote"] ?? 250, 
        pinned: pinnedColumns.find(x => x === "lastNote") ? true : false, 
        resizable: true, 
        hide: !this.directory.commentsEnabled, 
        headerComponent: 'headerRenderer' 
      });

      const origOrder = res.map(x => x.field);
      const o = [...res.filter(x => x.field !== '$details' && x.field !== '$menu')]
      o.sort((a, b) => {
        let ai = columnsOrder.indexOf(a.field);
        let bi = columnsOrder.indexOf(b.field);
        if(ai < 0) ai = origOrder.indexOf(a.field);
        if(bi < 0) bi = origOrder.indexOf(b.field);
        return ai > bi ? 1 : -1;
      });

      return [res[0], res[1], ...o];
    },
    ...mapGetters('directories', {
      directories: 'items'
    }),

    ...mapGetters('directoryItems', {
      itemsData: 'itemsData',
      persistentData: 'persistentData',
      deleting: 'deleting',
      processing: 'processing'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      userGroupIDs: 'userGroupIDs'
    }),
    items () {
      return (this.itemsData[this.directoryId] || {}).items || [];
    },
     directory () {
      return this.directories.find(x => x.id === this.directoryId) ||  { permissions: [], visibility: [] };
    },
    customFields() {
      return (this.directory.fields || []).filter(x => x.visibleOnGrid);
    },
    pinnedColumns() {
      return (this.persistentData[this.directoryId] || {}).pinnedColumns || [];
    },
    columnsOrder() {
      return (this.persistentData[this.directoryId] || {}).columnsOrder || [];
    },
    columnsWidth() {
      return (this.persistentData[this.directoryId] || {}).columnsWidth || {};
    }
  },
  methods:{
    ...mapMutations('directoryItems', {
       openItem: 'OPEN_ITEM'
    }),
    ...mapActions('directoryItems', {
      setPinnedColumns: 'setPinnedColumns',
      setColumnsOrders: 'setColumnsOrders',
      setColumnsWidth: 'setColumnsWidth',
      delete: 'delete'
    }),
    getRelatedFieldDef(directoryFieldId, directoryRelatedFieldId) {
      const refField = this.directory.fields.find(x => x.id === directoryFieldId);
      if(refField) {
        const directoryId = refField.directoryId;
        const directory = this.directories.find(x => x.id === directoryId);
        if(directory) {
          const directoryRelatedField = directory.fields.find(x => x.id === directoryRelatedFieldId);
          return directoryRelatedField;
        }
      }
      return null;
    },
    debCalculateHeight() {
      this.$nextTick(()=> this.calculateHeight());
      window.setTimeout(() => {
         this.calculateHeight()
      }, 1000);
    },
    calculateHeight() {
      const table = document.getElementById('directory-items-table') || this.$refs['table'];
      const windowHeight = window.innerHeight;
      const toolbar = document.getElementById("user-toolbar");
      const topBar = document.getElementById("page-topbar");

      if(table && toolbar) {
        const o = topBar.offsetHeight + toolbar.offsetHeight 
        + 40 //paddings
        ;
        const boardMaxHeight = windowHeight - o; // topbar + paddings

        table.style.maxHeight = boardMaxHeight + 'px';  
        table.style.height = boardMaxHeight + 'px';  
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
    onColumnResized(params) {
      const columns = params.columnApi.getAllGridColumns().filter(x => x.colDef.field !== '$details' && x.colDef.field !== '$menu');
      const o = {};
      columns.forEach(column => {
        const width = column.actualWidth;
        o[column.colDef.field] = width;
      });
      this.setColumnsWidth({ directoryId: this.directoryId, columnsWidth: o });
    },
    onColumnMoved (params) {
      const columns = params.columnApi.getAllGridColumns().filter(x => x.colDef.field !== '$details' && x.colDef.field !== '$menu');
      const columnsOrder= columns.map(x => x.colDef.field);
      this.setColumnsOrders({ directoryId: this.directoryId, columnsOrder: columnsOrder });
    },
    onColumnPinned(params) {
      const columns = params.columnApi.getAllGridColumns();
      const pinnedColumns = columns.filter(x => x.isPinned() && x.colDef.field !== '$details' && x.colDef.field !== '$menu')
      const pinnedIdColumns=pinnedColumns.map(x => x.colDef.field);
      
      this.setPinnedColumns({ directoryId: this.directoryId, pinnedColumns: pinnedIdColumns });
    },
    onDelete( item ) {
      Swal.fire({
        title: this.$t('common.confirmationTitle'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('common.yesDeleteIt')
      }).then(result => {
        if (result.value) {
          this.delete({directoryId: item.directoryId, id:item.id}).then(()=>{
            Swal.fire(this.$t('common.deletedTitle'), this.$t('common.deletedText'), "success");
          }).catch((error)=>{
            const errors = parseErrors(error);
            Swal.fire(this.$t('common.cannotDelete'), errors[0], "warning");
          });
        }
      });
    },
    getRowStyle(params) {
      const item = params.data;
      if(item && this.directory.type === 'Process') {
        const status = this.directory.statusList.find(x => x.id === item.status);
        if(status && status.highlightEntireRecord) {
          return {'background-color': status.color, 'color': status.fgColor || 'inherit'};
        }
      }
      return null;
    }
  }
};
</script>

<template>
 <div class="m-0">
    <ag-grid-vue
      ref="table"
      :enableCellTextSelection="true"
      :ensureDomOrder="true"
      id="directory-items-table"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      @grid-ready="onGridReady"
      @column-resized="onColumnResized"
      @column-moved="onColumnMoved"
      @column-pinned="onColumnPinned"
      :getRowStyle="getRowStyle"
      :rowData="items"
    >
    </ag-grid-vue>
  </div>
</template>
