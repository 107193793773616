<script>
import StatusColor from './status-color.vue';
import CustomFieldEdit from '../../../components/custom-fields/custom-field-edit.vue';
import {queryBuilderValueObject, queryBuilderDefaultQueryObj} from '@/helpers';
import http from '@/app-http'
import Tags from '@/components/tags'

function valueObject() {
  return queryBuilderValueObject();
}

export default {
  components: {
    StatusColor,
    CustomFieldEdit,
    Tags
},
  props: {
    tags: {
      type: Array,
      required: true
    },
    directories: {
      type: Array,
      required: true
    },
    directory: {
      type: Object,
      required: true
    },
    query: {
      type: Object
    }
  }, 
  data() {
    return {
      p_query: null,
      isDirty: false
    };
  },
  watch: {
   query(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.populate(this.query);
      }
    },
    p_query: {
      handler(newValue) {
        const o1 = JSON.stringify(this.createInternalObj(this.query));
        const o2 = JSON.stringify(newValue);
        if(o1 !== o2) {
          this.isDirty = true;
        }
      },
      deep: true
    }
  },
  computed: { 
   
    possibleConditions() {
      let res = [];
      if(!this.directory || !this.directory.fields)
        return res;
        
      this.directory.fields.forEach(field => {
        if(field.type !== 'Table' && field.type !== 'DirectoryRelatedField') {
          res.push({
            id: field.id,
            directory: this.directory,
            field: field,
            title: field.name,
            operators: this.getOperatorsForField(field)
          });
        } 
      });
      this.directory.fields.forEach(field => {
        if(field.type === 'Table') {
          console.log('table search', field.tableFields);
          field.tableFields.forEach(subField => {
             if(subField.type !== 'DirectoryRelatedField') {
              res.push({
                id: field.id + subField.id,
                directory: this.directory,
                field: subField,
                tableField: field,
                title: field.name + ' [' + subField.name + ']',
                operators: this.getOperatorsForField(field)
              });
            }
          });
        }
      });
      this.directories.forEach(dir => {
        const field = dir.fields.find(x => x.directoryId === this.directory.id);
        if(field && dir.type === 'Process' && field.showOnDirectoryForm) {
          res.push({
            id: field.id,
            directory: dir,
            field: 'Status',
            title: dir.name,
            operators: []
          });
        }
      });
      return res;
    },
    allowReset() {
      const o1 = this.getDefaultQueryObj();
      const o2 = this.p_query;
      return JSON.stringify(o1) !== JSON.stringify(o2);
    }
  },
  created() {
    this.populate(this.query);
  },
  methods: {
    isEmptyValue(v) {
      return !v.stringValue && !v.dateValue && !v.numberValue && !v.dirValue && v.statusList.length === 0;
    },
    createInternalObj(source) {
     
      try {
        if(source) {
          const sClone = JSON.parse(JSON.stringify(source));
          let conditions = [];
          sClone.conditions.forEach(c => {
          
            const directory = this.directories.find(x => x.id === c.directory);
            if(!directory) return;

            let tableField= null;
            let field = c.field === 'Status' ? 'Status' : directory.fields.find(x => x.id === c.field);
            if(!field) {
              directory.fields.forEach(f => {
                if(f.tableFields && !field) {
                  field = f.tableFields.find(x => x.id === c.field);
                  if(field) {
                    tableField = f;
                  }
                }
              })
            }

            // let subField = c.subField;
            // if(subField) {
            //   subField = field.tableFields.find(x => x.id === subField);
            //   if(!subField) return;
            // }

            const o = {
              op: c.op,
              lgOp: c.lgOp,
              value: c.value,
              id: c.id,
              directory: directory,
              field: field,
              // subField: subField,
              operators: field != 'Status' ? this.getOperatorsForField(field) : [],
              title: field == 'Status' ? directory.name : field.name + (tableField ?  (' [' + tableField.name + ']') : ''),
            };
            o.value.statusList = o.value.statusList || [];

            if(o.value.statusList.length > 0) {
              if(directory.type !== 'Process') {
                o.value.statusList = [];
              } else {
                o.value.statusList = o.value.statusList
                .map(id => directory.statusList.find(s => s.id === id))
                .filter(x => x);
              }
            }

            if(field.type === 'Directory' &&  c.value.stringValue) {
              const itemId = c.value.stringValue;
              o.value.dirValue = null;  
              o.value.stringValue = null;
              http.post(`directories/${field.directoryId}/items/search`, {
                itemId: itemId,
                take: 1,
                skip:0,
                orderBy: {
                  field: 'Number',
                  asc: true
                }
              }).then(response=>{
                o.value.dirValue = response.data.items.length > 0 ? response.data.items[0] : null;  
                o.value.stringValue = null;
                this.$nextTick( () => this.$forceUpdate());
              //  this.$forceUpdate()
              });  
            }

            conditions.push(o);
          });

          let p = {
            tags: sClone.tags || [],
            tagsLgOp: sClone.tagsLgOp,
            keyword: sClone.keyword,
            keywordLgOp: sClone.keywordLgOp,
            statusList: sClone.statusList.map(id => this.directory.statusList.find(o => o.id === id)).filter(obj => obj),
            statusLgOp: sClone.statusLgOp,
            conditions: conditions
          };
          return p;
        } else {
          return this.getDefaultQueryObj();
        }
      }catch(e) {
        console.error(e);
      }
    },
    populate(source) {
      console.log('query builder populate', source);
      this.p_query = this.createInternalObj(source);
      console.log('query builder populate', this.p_query);
      this.isDirty = false;
      this.$nextTick(()=>{
        this.$forceUpdate();
      });
    },
    reset() {
     this.p_query = this.getDefaultQueryObj();
     this.apply();
    },
    apply(){
      let { tags, tagsLgOp, keyword, keywordLgOp, statusList, statusLgOp, conditions } = this.p_query;
     
      conditions = conditions.filter(x => !this.isEmptyValue(x.value));
      
      let m = {
        tags, 
        tagsLgOp,
        keyword,
        keywordLgOp,
        statusList: statusList.map(x => x.id),
        statusLgOp,
        conditions: conditions.map(c => {
          const v = {...c.value};
          if((c.field || {}).type === 'Directory') {
            v.stringValue = v.dirValue ? v.dirValue.id : null;
            v.dirValue = null;
          }
          v.statusList = v.statusList.map(x => x.id);
          return {
            op: c.op,
            lgOp: c.lgOp,
            value: v,
            id: c.id,
            directory: c.directory.id,
            field: c.field === 'Status' ? c.field : c.field.id
          };
        }),
      };
      if(!m.keyword 
        && (m.tags || []).length == 0
        && (m.statusList.length === 0 || m.statusList.length === this.directory.length) 
        && m.conditions.length === 0) {
        m = null;
      }
     
      this.p_query = this.createInternalObj(m);
      console.log('apply model', JSON.parse(JSON.stringify( this.p_query)));
      this.$emit('apply', m);
      this.isDirty = false;
     
    },
    getDefaultQueryObj() {
      return queryBuilderDefaultQueryObj();
    },
    getOperatorsForField(field) {
      if(field.type === 'String' || field.type === 'Text' || field.type === 'Link' || field.type === 'File' || field.type === 'PhoneNumber' || field.type === 'Address') {
        return [
          'Contains',
          'StartWith',
          'Equal',
          'NotEqual',
        ]
      } else if(field.type === 'Dropdown' || field.type === 'Directory') {
        return [
          'Equal',
          'NotEqual'
        ]
      } else if(field.type === 'Date' || field.type === 'Number') {
        return [
          'Equal',
          'NotEqual',
          'Less',
          'LessOrEqual',
          'Greater',
          'GreaterOrEqual'
        ]
      }
      return [
          'Equal',
          'NotEqual'
        ];
    },
    toggleStatus(status) {
      if(this.p_query.statusList.find(x => x.id === status.id)) {
        this.p_query.statusList = this.p_query.statusList.filter(x => x.id !== status.id);
      } else {
        this.p_query.statusList = [...this.p_query.statusList, status];
      }
    },
    toggleRelDirectoryStatus(condition, status) {
      if(condition.value.statusList.find(x => x.id === status.id)) {
        condition.value.statusList = condition.value.statusList.filter(x => x.id !== status.id);
      } else {
        condition.value.statusList = [...condition.value.statusList, status];
      }
    },
    addCondition (possibleCondition) {

      const condition = {...possibleCondition };
      condition.op = possibleCondition.operators[0];
      condition.value = this.createValueObject();
      condition.lgOp = 'And';
      this.p_query.conditions = [...this.p_query.conditions, condition];
    },
    removeCondition(condition) {
      this.p_query.conditions = this.p_query.conditions.filter(x => x !== condition);
    },
    createValueObject() {
      return new valueObject();
    }
  }
};
</script>

<template>
  <div v-if="p_query">
    <div>
      <!-- Tags >>> -->
      <div class="mt-2 mb-2">
          <tags :tags="tags" :value="p_query.tags" @update="tags => p_query.tags = tags" :editable="true"></tags>
      </div>
       <!-- Tags <<< -->
      <div class="text-center mb-2 " v-if="tags.length > 0 && p_query.tags.length">
        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn btn-light waves-effect btn-sm"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            
          >
            <span v-if="p_query.keywordLgOp === 'And'">{{$t('common.and')}}</span>
            <span v-if="p_query.keywordLgOp === 'Or'">{{$t('common.or')}}</span>

            <span class="ms-2">
              <font-awesome-icon icon="fa-solid fa-caret-down" class=""/>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-end pt-2">
                
            <a class="dropdown-item"  href="javascript:void(0)"  @click="p_query.keywordLgOp = 'And'" v-if="p_query.keywordLgOp === 'Or'">
              <span>{{$t('common.and')}}</span>
            </a>

            <a class="dropdown-item"  href="javascript:void(0)"  @click="p_query.keywordLgOp = 'Or'" v-if="p_query.keywordLgOp === 'And'">
              <span>{{$t('common.or')}}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="mt-3 mb-2 bg-light border p-2" >
        <div class="">
          <div class="text-center text-dark mb-1"> <span>{{$t('common.keyword')}}</span></div>
          <input type="text" 
            v-model="p_query.keyword"
            class="form-control"
            :placeholder="$t('common.keyword')"     
          />
          <small class="text-muted">{{$t('common.searchByNumberHelpText')}}</small>
        </div>
      </div>
      <div class="text-center mb-2 " v-if="directory.type === 'Process'">
        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn btn-light waves-effect btn-sm"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            
          >
            <span v-if="p_query.statusLgOp === 'And'">{{$t('common.and')}}</span>
            <span v-if="p_query.statusLgOp === 'Or'">{{$t('common.or')}}</span>

             <span class="ms-2">
              <font-awesome-icon icon="fa-solid fa-caret-down" class=""/>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-end pt-2">
                
            <a class="dropdown-item"  href="javascript:void(0)"  @click="p_query.statusLgOp = 'And'" v-if="p_query.statusLgOp === 'Or'">
              <span>{{$t('common.and')}}</span>
            </a>

            <a class="dropdown-item"  href="javascript:void(0)"  @click="p_query.statusLgOp = 'Or'" v-if="p_query.statusLgOp === 'And'">
              <span>{{$t('common.or')}}</span>
            </a>

          </div>
        </div>
      </div>
      <div class="mb-2 bg-light border p-2" v-if="directory.type === 'Process'">
        <div class="text-center text-dark mb-1"> <span>{{$t('directory.fields.status')}}</span></div>
        <div class="dropdown ">
          <button
            :title="p_query.statusList.map(x => x.name).join(', ')"
            type="button"
            class="btn bg-white waves-effect waves-light"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-bs-auto-close="outside"
            style="position:relative; padding-right: 30px; width:100%"
          >
            <span v-if="p_query.statusList.length === 0 || p_query.statusList.length === directory.statusList.length">{{$t('common.allStatuses')}}</span>
            <template v-if="p_query.statusList.length > 0 && p_query.statusList.length < directory.statusList.length">
              <span class=" me-3 text-nowrap text-truncate" v-for="st in p_query.statusList" :key="st.id">
                <status-color :color="st.color"></status-color>{{st.name}}
              </span>
            </template>

            <span style="position: absolute; right:5px; top:10px">
              <font-awesome-icon icon="fa-solid fa-caret-down" class=""/>
            </span>
          
          </button>
          <div class="dropdown-menu dropdown-menu-end pt-2">
            <template v-for="status in directory.statusList || []" :key="status.id">
              <a 
                class="dropdown-item text-wrap"  
                href="javascript:void(0)" 
                :class="{'active': p_query.statusList.find(a => a.id === status.id)}" 
                @click="toggleStatus(status)"
              >
                <font-awesome-icon icon="fa-regular fa-square-check" v-if="p_query.statusList.find(a => a.id === status.id)"/>
                <font-awesome-icon icon="fa-regular fa-square"  class="text-muted" v-if="!p_query.statusList.find(a => a.id === status.id)" />
                <span class="ms-2"> 
                  <status-color :color="status.color"></status-color>
                  {{status.name}}
                </span>
              </a>
            </template>
          
          </div>
        </div>
      </div>
    </div>

    <template v-for="(condition, index) in p_query.conditions" :key="condition.id">
      <div class="text-center mb-2" v-if="index < p_query.conditions.length">
        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn btn-light waves-effect btn-sm"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            
          >
            <span v-if="condition.lgOp === 'And'">{{$t('common.and')}}</span>
            <span v-if="condition.lgOp === 'Or'">{{$t('common.or')}}</span>

            <span class="ms-2">
              <font-awesome-icon icon="fa-solid fa-caret-down" class=""/>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-end pt-2">
                
            <a class="dropdown-item"  href="javascript:void(0)"  @click="condition.lgOp = 'And'" v-if="condition.lgOp === 'Or'">
              <span>{{$t('common.and')}}</span>
            </a>

            <a class="dropdown-item"  href="javascript:void(0)"  @click="condition.lgOp = 'Or'" v-if="condition.lgOp === 'And'">
              <span>{{$t('common.or')}}</span>
            </a>

          </div>
        </div>
      </div>

      <div class="mb-2 bg-light border p-2" style="position:relative">
        <a href="javascript:void(0)" class="p-1" style="position: absolute; top:0; right:5px" @click="removeCondition(condition)">
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </a>

        <div class="text-center text-dark mb-1" v-if="condition.field !== 'Status'"> 
          <span>{{condition.title}}</span>
        </div>
        <div class="text-center text-dark mb-1" v-if="condition.field === 'Status'"> 
          <span>{{condition.title}} [{{$t('directory.fields.status')}}]</span>
        </div>

        <div class="text-center" v-if="condition.operators.length > 0 && condition.op">
          <div class="dropdown d-inline-block mb-1">
            <button
              type="button"
              class="btn btn-light waves-effect btn-sm"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              
            >
              {{$t('operators.' + condition.op)}}
              <span class="ms-2">
                <font-awesome-icon icon="fa-solid fa-caret-down" class=""/>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end pt-2">
              <template   v-for="op in condition.operators" :key="op">
                <a class="dropdown-item" 
                  href="javascript:void(0)"  
                  @click="condition.op = op" 
                  v-if="condition.op !== op"
                >
                  <span>  {{$t('operators.' + op)}}</span>
                </a>
              </template>
            </div>
          </div>
        </div> 
        
        <div v-if="condition.field === 'Status'">
          
          <div class="dropdown ">
            <button
            
              type="button"
              class="btn bg-white waves-effect waves-light"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-bs-auto-close="outside"
              style="position:relative; padding-right: 30px; width:100%"
            >
              <span v-if="condition.value.statusList.length === 0 || condition.value.statusList.length === condition.directory.statusList.length">{{$t('common.allStatuses')}}</span>
              <template v-if="condition.value.statusList.length > 0 && condition.value.statusList.length < condition.directory.statusList.length">
                <span class=" me-3 text-nowrap text-truncate" v-for="st in condition.value.statusList" :key="st.id">
                  <status-color :color="st.color"></status-color>{{st.name}}
                </span>
              </template>
             
              <span style="position: absolute; right:5px; top:10px">
                <font-awesome-icon icon="fa-solid fa-caret-down" class=""/>
              </span>
            
            </button>
            <div class="dropdown-menu dropdown-menu-end pt-2">
              <template v-for="status in condition.directory.statusList || []" :key="status.id">
                <a 
                  class="dropdown-item text-wrap"  
                  href="javascript:void(0)" 
                  :class="{'active': condition.directory.statusList.find(a => a.id === status.id)}" 
                  @click="toggleRelDirectoryStatus(condition, status)"
                >
                  <font-awesome-icon icon="fa-regular fa-square-check" v-if="condition.value.statusList.find(a => a.id === status.id)"/>
                  <font-awesome-icon icon="fa-regular fa-square"  class="text-muted" v-if="!condition.value.statusList.find(a => a.id === status.id)" />
                  <span class="ms-2"> 
                    <status-color :color="status.color"></status-color>
                    {{status.name}}
                  </span>
                </a>
              </template>
            
            </div>
          </div>
        </div>

        <div v-if="condition.field !== 'Status'">
          <div v-if="condition.field.type === 'String' || condition.field.type === 'Text' || condition.field.type === 'Link' || condition.field.type === 'Address' || condition.field.type === 'PhoneNumber'">
            <input class="form-control" v-model="condition.value.stringValue"/>
          </div>

          <div v-if="condition.field.type === 'Date' ">
            <input 
            
              type="date" 
              class="form-control" 
              v-model="condition.value.dateValue"
            />
          </div>

          <div v-if="condition.field.type === 'Number' ">
            <input 
              type="number" 
              class="form-control" 
              v-model.number="condition.value.numberValue"
            />
          </div>

          <div v-if="condition.field.type === 'Dropdown' ">
            <custom-field-edit :def="condition.field" :value="condition.value.stringValue" @update="(v) => condition.value.stringValue = v">
            </custom-field-edit>
          </div>

          <div v-if="condition.field.type === 'Directory' ">
          
            <custom-field-edit :def="condition.field" :value="condition.value.dirValue" @update="(v) => condition.value.dirValue = v">
            </custom-field-edit>
          </div>

        </div>
      </div>
    </template>

    <div class="" v-if="possibleConditions.length > 0">
      <div class="dropdown text-center">
        <button
          type="button"
          class="btn btn-link waves-effect"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <font-awesome-icon icon="fa-solid fa-plus" />
          <span class="ms-2">
            {{$t('common.addQueryCondition')}}
          </span>
          
        </button>
        <div class="dropdown-menu pt-2">
          <template v-for="possibleCondition in possibleConditions || []" :key="possibleCondition.id">
            <a 
              class="dropdown-item text-wrap"  
              href="javascript:void(0)" 
              @click="addCondition(possibleCondition)"
            >
              {{possibleCondition.title}}
            </a>
          </template>
        
        </div>
      </div>
    </div>

  
    <div class="row mt-4">
      <div class="col">
        <button class="btn btn-light" v-if="allowReset" @click="reset">
           {{$t('common.reset')}}
        </button>
      </div>
      <div class="col text-end">
        <button class="btn btn-primary"  v-if="isDirty" @click="apply">
          {{$t('common.apply')}}
        </button>
      </div>
    </div>
  </div>
</template>
