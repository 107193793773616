<script>
import StatusHeader from '../../boards/components/lane-header';
import StatusLane from './status-lane';
import {  mapGetters } from 'vuex';

export default {
  components: {
    StatusHeader,
    StatusLane
  },
  data() {
    return {
     
    };
  },
  props: {
    directory: {
      type: Object,
      required: true
    }
  },
  mounted () {
    window.addEventListener("resize", this.debCalculateHeight);
    this.debCalculateHeight();
  },
  unmounted() {
    window.removeEventListener("resize", this.debCalculateHeight);
  },
  computed :{
    ...mapGetters('directoryItems', {
       allItems: 'itemsData',
      persistentData: 'persistentData'
    }),
    dirItems() {
      return this.allItems[this.directory.id] || { board: { statusItems: {} }};
    },
    orderByField:
    {
      get: function() {
        const directoryId = (this.directory || {}).id || null;
        return ((this.persistentData[directoryId] || {}).boardOrderBy || {}).field || '$$status-pos$$';
      }
    },
  },
  methods: {
    getStatusItems(statusId) {
      //return (this.dirItems.board.statusItems || {})[this.status.id] || { total: 0, items: []};
      return (this.dirItems.board.statusItems || {})[statusId] || { total: 0, items: []};
    },
    debCalculateHeight() {
      this.$nextTick(()=> this.calculateHeight());
      window.setTimeout(() => {
         this.calculateHeight()
      }, 1000);
    },
    calculateHeight() {
      const table = document.getElementById('directory-items-table') || this.$refs['table'];
      const windowHeight = window.innerHeight;
      const toolbar = document.getElementById("user-toolbar");
      const topBar = document.getElementById("page-topbar");

      if(table && toolbar) {
        const o = topBar.offsetHeight + toolbar.offsetHeight 
        + 40 //paddings
        ;
        const boardMaxHeight = windowHeight - o; // topbar + paddings

        table.style.maxHeight = boardMaxHeight + 'px';  
        table.style.height = boardMaxHeight + 'px';  
      }
    },
  }
};
</script>

<template>
  <div class="table-responsive board-scrolable-container" ref="table"  id="directory-items-table">
    <table class="table project-list-table table-nowrap align-middle table-borderless board-table" style=" position: relative;border-spacing: 0" v-if="directory">
      <thead>
        <tr>
          <status-header 
            v-for="status in directory.statusList" 
            :status="status" 
            :directory="directory"
            :data="getStatusItems(status.id)"
            :key="status.id">
          </status-header>
          
          <th style=" position: sticky; top: 0;z-index: 1;background: white;">

          </th>
        </tr>
      </thead>
      
      <tbody>
        <tr>
          <template 
            v-for="status in directory.statusList" 
            :key="status.id"
          >
          
            <status-lane 
              :directory="directory"
              :status="status"
              :order-by-field="orderByField"
            >
            </status-lane>
          
          </template>
          <td class=" bg-light" style="border-top: 3px solid white;border-bottom: 3px solid white;border-right: 3px solid white;">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
