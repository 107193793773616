<script>
import { mapGetters, mapActions } from 'vuex';
import QueryBuilder from '../directories/query-builder.vue';

export default {
  components: {
    QueryBuilder
  },
   props: {
    directoryId: {
      type: String
    },
    filterKey:{
      type: String,
      required: true
    },
  },
  computed :{
    ...mapGetters('directoryItems', {
      allTags: 'tags'
    }),
    ...mapGetters('filters', {
      filters: 'filters'
    }),
    ...mapGetters('directories', {
      directories: 'items'
    }),
    tags() {
      return (this.allTags || {})[this.directoryId] || [];
    },  
    directory () {
      return this.directories.find(x => x.id === this.directoryId) || {};
    },
    filter(){
      return this.filters[this.filterKey] || null;
    }
  },
  methods:{
    ...mapActions('filters', {
      setFilter: 'setFilter'
    }),
    applyFilter(value) {
     
      this.setFilter({key: this.filterKey, value});
      this.$emit('changed')
    }
  }
};
</script>

<template>
  <query-builder :directories="directories" :directory="directory" :query="filter" :tags="tags" @apply="applyFilter">
  </query-builder>
</template>
