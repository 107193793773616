<script>


export default {
  components: {

  },
  created() {
    
  },
  props: {
    params: {
      type: Object
    }
  },
  computed: {
    def() {
      return this.params.def;
    },
    value() {
      return this.params.getValue();
    }
  },
};
</script>

<template>
  <custom-field :def="def" :value="value"/>
</template>
