<script>
import StatusColor from '../../../directories/status-color.vue';

export default {
  components: {  
    StatusColor
  },

  props: {
    params: {
      type: Object
    }
  },
  computed: {
    statusDef() {
      return this.params.directory.statusList.find(x => x.id === this.item.status);
    },
    item() {
      return this.params.data;
    }
  }
};
</script>

<template>
  <div v-if="statusDef">
    <status-color :color="statusDef.color"></status-color>{{statusDef.name}}
  </div>
</template>
