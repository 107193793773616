<script>
 import Tags from '@/components/tags'
export default {
 

  components: {  
    Tags
  },
  created() {
    
  },
  props: {
    params: {
      type: Object
    }
  },
  computed: {
    tags() {
      return this.params.getValue();
    }
  }
};
</script>

<template>
  <tags no-link :value="tags"></tags>
</template>
