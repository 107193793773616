<script>
import Layout from "../../layouts/main";
import { mapGetters, mapActions, mapMutations } from 'vuex';
import FilterRightSidebar from "./filter-right-sidebar";
import Pagination from '@/components/pagination';
import List from './list'
import Board from './board/board.vue'
import { queryBuilderDefaultQueryObj } from '@/helpers';
import { debounce } from "lodash";
import Tags from '@/components/tags'

export default {
  components: {
    Layout,
    FilterRightSidebar,
    Pagination,
    List,
    Board,
    Tags
  },
  data() {
    return {
      initializing: false,
      keyword: null
    };
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  created() {
    document.title = this.pagetitle;
    this.init();
  },
  computed :{
    ...mapGetters('usageProfiles', {
      currentUsageProfile: 'current'
    }),
     ...mapGetters('boot', {
      appReady: 'ready'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      userGroupIDs: 'userGroupIDs',
      token: 'token'
    }),
    ...mapGetters('filters', {
      filters: 'filters'
    }),
    ...mapGetters('directories', {
      directories: 'items'
    }),
    ...mapGetters('directoryItems', {
      loading: 'loading',
      itemsData: 'itemsData',
      persistentData: 'persistentData',
      perPage: 'perPage',
      allTags: 'tags'
    }),
    isBusy() {
      return this.loading;
    },
    tags() {
      return (this.allTags || {})[this.type] || [];
    },  
    allowNew() {
      if(this.currentUser.isAdmin )
        return true;
      
      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowCreate;
    },
    directoryId () {
      return (this.directory || {  }).id || null;
    },
    directory () {
      return this.directories.find(x => x.id === this.type) || { permissions: [], visibility: [] };
    },
    pagetitle () {
      return this.directory.name || 'Loading...';
    },
    directoryData () {
      return this.itemsData[this.directoryId] || {
        total:0,
        page:1,
        items: []
      };
    },
    total() {
      return this.directoryData.total;
    },
    view: {
      get: function() {
        return (this.persistentData[this.directoryId] || {}).view;
      },
      set: function(newValue) {
        this.setView( { directoryId: this.directoryId, newValue });
      }
    },
    currentPage:
    {
      get: function() {
        return this.directoryData.page || 1;
      },
      set: function(newValue) {
        this.setPage({directoryId: this.directoryId, page: newValue});
      }
    },
    orderByAsc:
    {
      get: function() {
        if(this.view === 'board') {
          return ((this.persistentData[this.directoryId] || {}).boardOrderBy || {}).asc || false;
        } else {
          return ((this.persistentData[this.directoryId] || {}).orderBy || {}).asc || false;
        }
      },
      set: function(newValue) {
        if(this.view === 'board') {
          this.setBoardOrderBy({directoryId: this.directoryId, field: this.orderByField, asc: newValue});
        } else {
          this.setOrderBy({directoryId: this.directoryId, field: this.orderByField, asc: newValue});
        }
      }
    },
    orderByField:
    {
      get: function() {
        if(this.view === 'board') {
           return ((this.persistentData[this.directoryId] || {}).boardOrderBy || {}).field || '$$status-pos$$';
        } else {
           return ((this.persistentData[this.directoryId] || {}).orderBy || {}).field || 'Number';
        }
      },
      set: function(newValue) {
        if(this.view === 'board') {
           this.setBoardOrderBy({directoryId: this.directoryId, asc: this.orderByAsc, field: newValue});
        } else {
           this.setOrderBy({directoryId: this.directoryId, asc: this.orderByAsc, field: newValue});
        }
        
      }
    },
    customFields() {
      return (this.directory.fields || []).filter(x => !x.deletedAt && x.visibleOnGrid);
    },
    filterKey() {
      const key = (this.directoryId) + '-list-or-board';
      //console.log('filterKey', key);
      return key;
    },  
    filter(){
      return this.filters[this.filterKey] || null;
    },
    filterTags:
    {
      get: function() {
        return (this.filter || {}).tags || [];
      },
      set: async function(newValue) {
        const data = JSON.parse(JSON.stringify(this.filter || queryBuilderDefaultQueryObj()));
        data.tags = newValue;
        if(!data.tagsLgOp)
          data.tagsLgOp = 'And';
        await this.setFilter({key: this.filterKey, value: data });
        this.debSearch();
      }
    },
    // keyword:
    // {
    //   get: function() {
    //     return (this.filter || {}).keyword || "";
    //   },
    //   set: function(newValue) {
    //     const data = JSON.parse(JSON.stringify(this.filter || queryBuilderDefaultQueryObj()));
    //     data.keyword = newValue;
    //     if(!data.keywordLgOp)
    //       data.keywordLgOp = 'And';
    //     await this.setFilter({key: this.filterKey, value: data });
    //     this.debSearch();
    //   }
    // },
    jsonDataUrl() {
      return `${process.env.VUE_APP_URL}data/json?dir=${this.directoryId}&token=${this.token}`;
    }
  },
  watch: {
    appReady(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.init();
      }
    },
    pagetitle(newValue, oldValue) {
      if(newValue !== oldValue) {
        document.title = this.pagetitle;
        this.initializing = false;
        this.init();
      }
    }
  },
  methods: {
    ...mapActions('filters', {
      setFilter: 'setFilter'
    }),
    ...mapMutations('directoryItems', {
      openItem: 'OPEN_ITEM'
    }),
    ...mapActions('filters', {
      loadFilterUserSettings: 'loadUserSettings'
    }),
    ...mapActions('directoryItems', {
      initStore: 'init',
      load: 'load',
      loadToJson: 'loadToJson',
      setOrderBy: 'setOrderBy',
      setView: 'setView',
      setPage: 'setPage',
      setBoardOrderBy: 'setBoardOrderBy'
    }),
    exportToJson() {
      this.loadToJson();
    },
    async init() {
      if(this.appReady) {
        await this.loadFilterUserSettings();
        if(this.directoryId) {
          const defaultView = this.directory.type === 'Process' ? 'board' : 'list';
          await this.initStore({directoryId: this.directoryId, defaultView, filterKey: this.filterKey});
          this.keyword = ((this.filter || {}).keyword || "");
        }
      }
    },
    async search() {
      const data = JSON.parse(JSON.stringify(this.filter || queryBuilderDefaultQueryObj()));
      data.keyword = this.keyword;
      if(!data.keywordLgOp)
        data.keywordLgOp = 'And';
      await this.setFilter({key: this.filterKey, value: data });
      this.currentPage = 1;
      this.load()
    },
    toggleFilter () {
      document.body.classList.toggle("right-bar-enabled");
    },
    newItem() {
      this.openItem ( { item: { directoryId: this.directoryId }, mode:'edit'});
    },
    cloneFilter(){
      return JSON.parse(JSON.stringify(this.filter));
    },
    debSearch: debounce(function(){
      this.load();
    }, 600),
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle" v-if="appReady" :is-busy="isBusy">
    <filter-right-sidebar v-if="directoryId && !initializing" :directory-id="directoryId" :filter-key="filterKey" @changed="debSearch">
    </filter-right-sidebar>
    <div  v-if="!initializing" ref="toolbar" id="user-toolbar">
      <div class="row">
        <div class="col-md-6">
          <pagination :page="currentPage" :total="total" :per-page="perPage" @change="(v) => currentPage = v" v-if="view !== 'board'"/>
          <div class="row" v-if="view === 'board'">
            <div class="col-auto">
              <div class="mb-2 d-none d-lg-block" >
                <div class="input-group">
                  <input class="form-control" :placeholder="$t('common.search')" v-model="keyword" v-on:keyup.enter="search"/>
                  <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" @click="search"><font-awesome-icon icon="fa-solid fa-magnifying-glass" /></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="mb-2 d-none d-lg-block" style="position:relative;">
                <tags :tags="tags" :value="filterTags" @update="tags => filterTags = tags" :editable="true" :add-tag-text="$t('common.searchByTag')"></tags>
              </div>
            </div>
            
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="d-flex flex-wrap align-items-center justify-content-end "
          >
          <div class="ms-2 mb-2" v-if="allowNew">
            <button
              type="button"
              class="btn btn-primary"
              @click="newItem"
              > 
              <font-awesome-icon icon="fa-solid fa-plus" />
              <div class="text-sm-end d-none d-lg-inline-block ms-2">
                {{ $t('common.newRecord') }}
              </div>
            </button>
          </div>
          
          <div class=" ms-2 mb-2">
            <div class="dropdown d-inline-block">
              <button
                  type="button"
                  class="btn btn-outline-secondary waves-effect text-dark"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <div class="text-sm-end d-none d-lg-inline-block">
                    <span class="me-2" v-if="orderByField === '$$status$$'">
                      {{$t('directory.fields.status')}}
                    </span>
                    <span class="me-2" v-if="orderByField === '$$status-pos$$'">
                      {{$t('common.manual')}}
                    </span>
                    <span class="me-2" v-if="orderByField !== '$$status-pos$$'">
                      <span v-if="orderByField === 'Number'">{{$t('directory.fields.number')}}</span>
                      <span>{{((customFields.find(x => x.id === orderByField) || {}) || {}).name}}</span>
                    </span>
                  </div>
                  <font-awesome-icon icon="fa-solid fa-arrow-down-a-z" v-if="orderByAsc" />
                  <font-awesome-icon icon="fa-solid fa-arrow-up-z-a" v-if="!orderByAsc"/>
                  <font-awesome-icon icon="fa-solid fa-caret-down" class="ms-1"/>
              </button>
              <div class="dropdown-menu dropdown-menu-end pt-2">
               
                <a class="dropdown-item" :class="{'active': orderByField === '$$status-pos$$'}" href="javascript:void(0)" v-if="view === 'board'"  @click="orderByField = '$$status-pos$$'">
                  <font-awesome-icon icon="fa-regular fa-square-check" v-if="orderByField === '$$status-pos$$'"/>
                  <font-awesome-icon icon="fa-regular fa-square"  class="text-muted" v-if="orderByField !== '$$status-pos$$'" />
                  <span class="ms-2"> {{$t('common.manual')}}</span>
                </a>
                <a class="dropdown-item" :class="{'active': orderByField === 'Number'}" href="javascript:void(0)"  @click="orderByField = 'Number'">
                  <font-awesome-icon icon="fa-regular fa-square-check" v-if="orderByField === 'Number'"/>
                  <font-awesome-icon icon="fa-regular fa-square" class="text-muted" v-if="orderByField !== 'Number'" />
                  <span class="ms-2">{{$t('directory.fields.number')}}</span>
                </a>
                <a class="dropdown-item" :class="{'active': orderByField === '$$status$$'}" href="javascript:void(0)"  @click="orderByField = '$$status$$'">
                  <font-awesome-icon icon="fa-regular fa-square-check" v-if="orderByField === '$$status$$'"/>
                  <font-awesome-icon icon="fa-regular fa-square"  class="text-muted" v-if="orderByField !== '$$status$$'" />
                  <span class="ms-2"> {{$t('directory.fields.status')}}</span>
                </a>
                <a class="dropdown-item" :class="{'active': orderByField === f.id}" href="javascript:void(0)" v-for="f in customFields" :key="f.id" @click="orderByField =  f.id">
                  <font-awesome-icon icon="fa-regular fa-square-check" v-if="orderByField === f.id"/>
                  <font-awesome-icon icon="fa-regular fa-square"  class="text-muted" v-if="!(orderByField === f.id)" />
                  <span class="ms-2">{{f.name}}</span>
                </a>
                <hr v-if="orderByField !== '$$status-pos$$'"/>
                <div class="text-center mb-2" v-if="orderByField !== '$$status-pos$$'"> 
                  <div class="btn-group" role="group">
                    <button class="btn btn-sm" :class="{'btn-outline-primary': !orderByAsc, 'btn-primary': orderByAsc}" @click="orderByAsc =  true">
                      <font-awesome-icon icon="fa-solid fa-arrow-down-a-z" class="me-2"  />
                      Asc
                    </button>
                    <button class="btn btn-sm" :class="{'btn-outline-primary': orderByAsc, 'btn-primary': !orderByAsc}" @click="orderByAsc =  false">
                      <font-awesome-icon icon="fa-solid fa-arrow-up-z-a" class="me-2"  />
                      Desc
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="ms-2 mb-2">
            <div class="dropdown d-inline-block">
              <button
                type="button"
                class="btn btn-outline-secondary waves-effect text-dark"
                
                @click.stop.prevent="toggleFilter(slotProps)"
              >
                <font-awesome-icon icon="fa-solid fa-filter"/>
                <div class="text-sm-end d-none d-lg-inline-block ms-2">
                    {{ $t('common.filters') }}
                </div>
              </button>
            </div>
          </div>
          
          <div class=" ms-2 mb-2" v-if="directory.type === 'Process'">
            <div class="dropdown d-inline-block">
              <button
                  type="button"
                  class="btn btn-outline-secondary waves-effect text-dark"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                
                >
                  <font-awesome-icon icon="fa-solid fa-list-ul" v-if="view === 'list'"/>
                  <font-awesome-icon icon="fa-solid fa-chalkboard" v-if="view === 'board'"/>

                  <div class="text-sm-end d-none d-lg-inline-block">
                    <span class="ms-2">
                      <span v-if="view === 'board'">{{$t('common.board')}}</span>
                      <span v-if="view === 'list'">{{$t('common.list')}}</span>
                    </span>
                  </div>
                
                  <i class="fa-caret-down fa ms-1"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-end pt-2">
                
                <a class="dropdown-item" :class="{'active': view === 'list'}" href="javascript:void(0)"  @click="view = 'board'">
                  <i class="mdi  mdi-bulletin-board"></i><span class="ms-2">{{$t('common.board')}}</span>
                </a>

                <a class="dropdown-item" :class="{'active': view === 'list'}" href="javascript:void(0)"  @click="view = 'list'">
                  <i class="mdi mdi-format-list-bulleted"></i><span  class="ms-2">{{$t('common.list')}}</span>
                </a>

              </div>
            </div>
          </div>

          <div class="ms-2 mb-2" v-if="currentUser.isAdmin">
            <!-- <a :href="jsonDataUrl" class="btn btn-outline-secondary">
              <font-awesome-icon icon="fa-solid fa-file-export" />
            </a> -->
            <a href="javascript:void(0)" @click="exportToJson" class="btn btn-outline-secondary">
              <font-awesome-icon icon="fa-solid fa-file-export" />
            </a>
          </div>
        </div>
          
        </div>
        
      </div>
      <div class="row"  v-if="view === 'list'">
        <div class="col-auto ">
          <div class="mb-2 d-none d-lg-block" >
            <div class="input-group">
              <input class="form-control" :placeholder="$t('common.search')"  v-model="keyword" v-on:keyup.enter="search"/>
              <span class="text-muted" style="position:absolute; top:9px; left:10px" >
                
              </span>
              <div class="input-group-append">
                <button class="btn btn-secondary" type="button" @click="search"><font-awesome-icon icon="fa-solid fa-magnifying-glass" /></button>
              </div>
            </div>
            
          </div>
        </div>
        <div class="col">
          <div class="mb-2 d-none d-lg-block" style="position:relative;">
            <tags :tags="tags" :value="filterTags" @update="tags => filterTags = tags" :editable="true" :add-tag-text="$t('common.searchByTag')"></tags>
          </div>
        </div>
        <div class="col-auto">
          Всього: <b>{{total}}</b>
        </div>
      </div>
    </div>
    
    <div class="mt-2 mt-md-0" style="">
      <div v-if="!initializing && directory">
        <list :directory-id="directoryId" v-if="view === 'list'"></list>
        <board :directory="directory" v-if="view === 'board'"></board>
      </div>
    </div>
  </Layout>
</template>
